import $ from 'jquery';

import './scss/style.scss';

export const TABLET_BREAKPOINT = 768;
export const DESKTOP_BREAKPOINT = 1024;

const $window = $(window);
const hasWindow = $window && $window.length;
const $document = $(document);
const hasDocument = $document && $document.length;

if (hasWindow && hasDocument) {
  const $header = $('#header');
  const hasHeader = $header && $header.length;
  const $headerTop = $header.find('.header-top');
  const hasHeaderTop = $headerTop && $headerTop.length;
  const $anchorLink = $('.anchor-link');
  const hasAnchorLink = $anchorLink && $anchorLink.length;
  const $carouselComponent = $('.carousel-component');
  const hasCarouselComponent = $carouselComponent && $carouselComponent.length;
  const $accordionComponent = $('.accordion-component');
  const hasAccordionComponent = $accordionComponent && $accordionComponent.length;

  let windowWidth = 0;
  let headerTopHeight = 0;
  let device = 'mobile';

  const setHeaderTopHeight = () => {
    if (hasHeaderTop) {
      headerTopHeight = $headerTop.innerHeight();
    };
  };

  const setWindowWidth = () => windowWidth = $window.innerWidth();
  
  const setDevice = () => {
    if (
      device !== 'mobile' &&
      windowWidth < TABLET_BREAKPOINT
    ) {
      device = 'mobile';
    } else if (
      device !== 'tablet' &&
      windowWidth >= TABLET_BREAKPOINT &&
      windowWidth < DESKTOP_BREAKPOINT) {
        device = 'tablet';
    } else if (
      device !== 'desktop' &&
      windowWidth >= DESKTOP_BREAKPOINT
    ) {
      device = 'desktop';
    }
  };

  const smoothScroll = scrollTop => $('html, body').animate({ scrollTop }, 800);

  const getCarouselStep = $carousel => {
    if ($carousel && $carousel.length) {
      switch (device) {
        case 'mobile':
          return parseInt($carousel.attr('step-mobile'));
        case 'tablet':
          return parseInt($carousel.attr('step-tablet'));
        case 'desktop':
          return parseInt($carousel.attr('step-desktop'));
        default:
          return 1;
      }
    }
  };

  const onClickCarouselNav = ($carousel, direction) => {
    if ($carousel && $carousel.length) {
      const step = getCarouselStep($carousel);
      const position = parseInt($carousel.attr('position')) + (step * direction);

      updateCarousel($carousel, position);
    }
  };

  const onClickCarouselNavBullet = ($carousel, $bullet) => {
    if ($carousel && $carousel.length && $bullet && $bullet.length) {
      const position = parseInt($bullet.attr('position'));

      updateCarousel($carousel, position);
    }
  };

  const onClickAccordionBoxHead = event => {
    const $target = $(event.currentTarget);
    const $box = $target.closest('.accordion-box');
    
    if ($box && $box.length) {
      if ($box.hasClass('show')) {
        $box.removeClass('show');
      } else {
        $box.addClass('show');
      }
    }
  }

  const onClickShowMoreModules = ($showMore, $accordion) => {
    const $box = $accordion.find('.accordion-box');

    $accordion.addClass('all-visible');
    $box.addClass('visible');
    $showMore.addClass('hide');
  };

  const updateCarousel = ($carousel, position) => {
    if ($carousel && $carousel.length) {
      const $box = $carousel.find('.carousel-box');
      const $last = $carousel.parent().find('.carousel-nav-last');
      const $next = $carousel.parent().find('.carousel-nav-next');
      const $bullet = $carousel.parent()
        .find('.carousel-nav-position .carousel-nav-bullet .bullet-button');
      const step = getCarouselStep($carousel);

      $last.removeClass('hide');
      $next.removeClass('hide');

      if (position >= $box.length) {
        position = $box.length - 1;
      }

      if (position + step >= $box.length) {
        $next.addClass('hide');
      }

      if (position <= 0) {
        $last.addClass('hide');
        position = 0;
      }

      $carousel.attr('position', position);
      $box.css('transform', `translateX(${-100 * position}%)`);
      $bullet.removeClass('active');
      $bullet.eq(position).addClass('active');
    }
  };

  const setupCarousel = () => {
    $carouselComponent.each(index => {
      const $carousel = $carouselComponent.eq(index);
      const $box = $carousel.find('.carousel-box');
      const $last = $carousel.parent().find('.carousel-nav-last');
      const $next = $carousel.parent().find('.carousel-nav-next');
      const $bullet = $carousel.parent()
        .find('.carousel-nav-position .carousel-nav-bullet .bullet-button');
      const stepMobile = parseInt($carousel.attr('step-mobile'));
      const stepTablet = parseInt($carousel.attr('step-tablet'));
      const stepDesktop = parseInt($carousel.attr('step-desktop'));

      if ($box.length <= stepMobile) {
        $carousel.addClass('hide-nav-mobile');
      }

      if ($box.length <= stepTablet) {
        $carousel.addClass('hide-nav-tablet');
      }

      if ($box.length <= stepDesktop) {
        $carousel.addClass('hide-nav-desktop');
      }

      $carousel.attr('position', 0);
      $last.addClass('hide');
      $bullet.eq(0).addClass('active');
      $bullet.click(event => onClickCarouselNavBullet(
        $carousel,
        $(event.currentTarget)
      ));
      $last.click(() => onClickCarouselNav($carousel, -1));
      $next.click(() => onClickCarouselNav($carousel, 1));
    });
  };

  const resetCarousel = () => {
    $carouselComponent.each(index => {
      const $carousel = $carouselComponent.eq(index);
      const step = getCarouselStep($carousel);
      const position = parseInt($carousel.attr('position'));

      if (position % step === 0) {
        updateCarousel($carousel, position);
      } else {
        updateCarousel($carousel, 0);
      }
    });
  };

  const setupAccordion = () => {
    $accordionComponent.each(index => {
      const $accordion = $accordionComponent.eq(index);
      const $box = $accordion.find('.accordion-box');
      const $boxHead = $accordion.find('.accordion-box-head');
      const visibleTtems = parseInt($accordion.attr('visible'));
      const $showMore = $accordion.parent().find('.show-more-modules');

      if (visibleTtems && visibleTtems > 0) {
        $box.slice(0, visibleTtems).addClass('visible no-transition');
      }

      if ($boxHead && $boxHead.length) {
        $boxHead.click(onClickAccordionBoxHead);
      }

      if ($showMore && $showMore.length && visibleTtems && visibleTtems > 0) {
        if ($box && $box.length && $box.length < visibleTtems) {
          $accordion.addClass('all-visible');
          $box.addClass('visible no-transition');
          $showMore.addClass('hide');
        } else {
          $box.slice(0, visibleTtems).addClass('visible no-transition');
          $showMore.click(() => { onClickShowMoreModules($showMore, $accordion) });
        }
      }
    });
  };

  const onWindowResize = () => {
    setHeaderTopHeight();
    setWindowWidth();
    setDevice();
    resetCarousel();
  };

  const onDocumentReady = () => {
    onWindowResize();

    if (hasHeader) {
      const $toggleMenu = $header.find('.toggle-menu');
  
      const onClickToggleMenu = () => {
        $header.scrollTop(0);
  
        if ($header.hasClass('show-menu')) {
          $header.removeClass('show-menu');
        } else {
          $header.addClass('show-menu');
        }
      };
  
      $toggleMenu.click(onClickToggleMenu);
    }

    if (hasAnchorLink) {
      const onClickAnchorLink = event => {
        event.preventDefault();

        const $current = $(event.currentTarget);
        const $target = $($current.attr('href'));

        if ($header.hasClass('show-menu')) {
          $header.removeClass('show-menu');
        }

        if ($target && $target.length) {
          const scrollTop = $target.offset().top - (1.25 * headerTopHeight);

          smoothScroll(scrollTop);
        }
      };

      $anchorLink.click(onClickAnchorLink);
    }

    if (hasCarouselComponent) {
      setupCarousel();
    }

    if (hasAccordionComponent) {
      setupAccordion();
    }
  }

  $window.resize(onWindowResize);
  $document.ready(onDocumentReady);
}
